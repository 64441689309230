<template lang="pug">
.text-center.mb-0.p-0(role="alert" :class="barClasses")
  .box-center
    template(v-if="getLimitProblemType !== null")
      .icon(v-if="hasAlert")
        UilExclamationTriangle
      .text(style="max-width: 100%; text-align: left")
        span {{ $t(`limitReachedAlert.${getLimitProblemType}.line1.${packageTranslateKey}`, { used: addThousandSep(usedLimit), max: addThousandSep(maxLimit) }) }}
        br
        span {{ $t(`limitReachedAlert.${getLimitProblemType}.line2.${packageTranslateKey}`) }}
      .button
        om-button(v-if="!isInActionPage" primary @click="modifyPlan") {{ $t(`limitReachedAlert.${getLimitProblemType}.button`) }}
</template>

<script>
  import { mapGetters, createNamespacedHelpers } from 'vuex';
  import { UilExclamationTriangle } from '@iconscout/vue-unicons';
  import { getAccountIdFromCookie } from '@/util';
  import upgradePlanModal from '@/mixins/upgradePlanModal';

  const { mapGetters: paymentGetters } = createNamespacedHelpers('payment');

  export default {
    components: {
      UilExclamationTriangle,
    },

    mixins: [upgradePlanModal],

    computed: {
      ...mapGetters(['isPageViewBasedPackage']),
      ...paymentGetters(['getLimitProblemType', 'usedLimit', 'maxLimit']),
      packageTranslateKey() {
        if (this.isPageViewBasedPackage) {
          return 'with_PageViewBasePackage';
        }
        return 'with_VisitorBasePackage';
      },
      barClasses() {
        return this.hasAlert ? { alert: true, 'alert-danger': true } : { info: true, alert: true };
      },
      hasAlert() {
        return this.getLimitProblemType !== 'non-paying-over-50p';
      },
      isInActionPage() {
        if (['checkout', 'shopify_checkout', 'plan_settings'].includes(this.$route.name))
          return true;
        return false;
      },
    },

    methods: {
      modifyPlan() {
        this.$router.push({
          name: 'plan_settings',
          params: { userId: getAccountIdFromCookie() },
        });
      },
      addThousandSep(value) {
        return this.$options.filters.thousandSep(value);
      },
    },
  };
</script>
